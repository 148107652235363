@import "~antd/lib/style/themes/default.less";

.fcd-icon {
  --fcd-icon-background: #00d924;
  --fcd-icon-foreground: #0073e6;
  --fcd-icon-intersection: #00299c;
  
  display: inline-block;
  flex-shrink: 0;
  line-height: 1;

  width: 40px;
  height: 40px;

  &-container {
    position: relative;

    > svg {
      position: absolute;
      top: -25px;
      left: -15px;
      display: block;
      pointer-events: none;
    }
  }

  > svg {
    overflow: hidden;
    pointer-events: none;
    stop-color: currentColor;

    max-width: 100%;
    max-height: 100%;
    color: var(--icon-primary-color);
    fill: var(--icon-secondary-color);
    vertical-align: bottom;
  }

  @media screen and (forced-colors: active) {
    > svg {
      --icon-primary-color: CanvasText;
      --icon-secondary-color: Canvas;

      filter: grayscale(1);
    }
  }

  &-samecolor {
    @media screen and (forced-colors: active) {
      > svg {
        --icon-primary-color: Canvas;
      }
    }
  }

  &-transparent {
    @media screen and (forced-colors: active) {
      > svg {
        --icon-secondary-color: transparent;
      }
    }
  }

  &-small {
    width: 16px;
    height: 16px;
  }

  &-medium {
    width: 24px;
    height: 24px;
  }

  &-large {
    width: 32px;
    height: 32px;
  }

  &-xlarge {
    width: 48px;
    height: 48px;
  }
}
@ant-prefix: fcd;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@primary-color: #6ec72d;@link-color: #6ec72d;@body-background: #f6f9fc;@component-background: #ffffff;@text-color: #172B4D;@text-color-inverse: #ffffff;@icon-color: #6ec72d;@icon-color-hover: #00d924;@layout-body-background: @body-background;@layout-header-background: @body-background;@layout-header-height: 65px;@layout-header-padding: 0 50px;@menu-bg: transparent;@form-vertical-label-padding: 0 0 0;