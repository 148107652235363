@import "~antd/lib/style/themes/default.less";

.fcd-form-section {
  margin-top: @margin-lg;

  .header {
    position: relative;
    margin-bottom: @margin-lg;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -25px;
      width: 1px;
      height: 100%;
      background-color: @primary-color;
    }
  }

  .icon {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    min-height: 40px;
    padding-right: @padding-md;
  }

  .title {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: center;
    margin-bottom: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@ant-prefix: fcd;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@primary-color: #6ec72d;@link-color: #6ec72d;@body-background: #f6f9fc;@component-background: #ffffff;@text-color: #172B4D;@text-color-inverse: #ffffff;@icon-color: #6ec72d;@icon-color-hover: #00d924;@layout-body-background: @body-background;@layout-header-background: @body-background;@layout-header-height: 65px;@layout-header-padding: 0 50px;@menu-bg: transparent;@form-vertical-label-padding: 0 0 0;