@import "~antd/lib/style/themes/default.less";

.fcd-layout-header {
  margin-top: @margin-lg;
  margin-bottom: @margin-lg;
  
  .fcd-logo-wrapper {
    margin-bottom: 0;
    text-align: center;
  }

  .fcd-menu {
    justify-content: flex-end;
    border-bottom: 0;
    background-color: transparent;

    .fcd-menu-item {
      font-weight: 600;
    }
  }
}
@ant-prefix: fcd;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@primary-color: #6ec72d;@link-color: #6ec72d;@body-background: #f6f9fc;@component-background: #ffffff;@text-color: #172B4D;@text-color-inverse: #ffffff;@icon-color: #6ec72d;@icon-color-hover: #00d924;@layout-body-background: @body-background;@layout-header-background: @body-background;@layout-header-height: 65px;@layout-header-padding: 0 50px;@menu-bg: transparent;@form-vertical-label-padding: 0 0 0;