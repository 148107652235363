@import "~antd/lib/style/themes/default.less";

.fcd-guides {
  display: none;
  position: absolute;
  width: calc(100% - 50px);
  height: 100%;
  overflow: visible;
  pointer-events: none;

  .container {
    display: grid;
    grid: 1fr/repeat(2,1fr);
    height: 100%;

    .guide {
      width: 1px;
      background: rgba(66, 71, 112, 0.06);
      
      &:last-of-type {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
      }
    }
  }
}
@ant-prefix: fcd;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@primary-color: #6ec72d;@link-color: #6ec72d;@body-background: #f6f9fc;@component-background: #ffffff;@text-color: #172B4D;@text-color-inverse: #ffffff;@icon-color: #6ec72d;@icon-color-hover: #00d924;@layout-body-background: @body-background;@layout-header-background: @body-background;@layout-header-height: 65px;@layout-header-padding: 0 50px;@menu-bg: transparent;@form-vertical-label-padding: 0 0 0;