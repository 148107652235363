@import "~antd/lib/style/themes/default.less";

.fcd-vehicles {
  position: relative;
  padding: @padding-md 0;
  width: 100%;

  .fcd-radio-wrapper {
    width: 100%;

    .tick {
      --fcd-icon-background: #ccc;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 10px;
      left: 20px;
      z-index: 2;
      opacity: 0.4;
    }

    &-checked .tick {
      opacity: 1;
      --fcd-icon-background: #6ec72d;
    }

    .fcd-image {
      text-align: center;

      .fcd-image-img {
        width: auto;
      }
    }

    & .fcd-card-bordered .fcd-card-cover {
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
    }

    &-checked .fcd-card-bordered {
      border: 1px solid @primary-color;
    }

    .fcd-radio {
      display: none;
    }

    span.fcd-radio + * {
      width: 100%;
    }
  }
}

@media screen and (max-width: @screen-md) {
  .fcd-vehicles {
    & .fcd-card {
      margin-bottom: @padding-md;
    }
  }
}
@ant-prefix: fcd;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@primary-color: #6ec72d;@link-color: #6ec72d;@body-background: #f6f9fc;@component-background: #ffffff;@text-color: #172B4D;@text-color-inverse: #ffffff;@icon-color: #6ec72d;@icon-color-hover: #00d924;@layout-body-background: @body-background;@layout-header-background: @body-background;@layout-header-height: 65px;@layout-header-padding: 0 50px;@menu-bg: transparent;@form-vertical-label-padding: 0 0 0;