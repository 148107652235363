@import "~antd/lib/style/themes/default.less";

.fcd-layout {
  position: relative;
  width: 100%;

  display: flex;
  flex-wrap: nowrap;

  margin-left: auto;
  margin-right: auto;

  &-content {
    padding: 0;
  }

  &-footer {
    text-align: center;
  }
}

@media screen and (min-width: @screen-md) {
  .fcd-layout {
    max-width: 720px;
    padding-left: @padding-md;
    padding-right: @padding-md;
  }

  .fcd-layout-content {
    padding: 0 25px;
  }
}

@media screen and (min-width: @screen-lg) {
  .fcd-layout {
    max-width: 960px;
    padding-left: @padding-lg;
    padding-right: @padding-lg;
  }

  .fcd-layout-content {
    padding: 0 50px;
  }
}

@media screen and (min-width: @screen-xl) {
  .fcd-layout {
    max-width: 1140px;
    padding-left: @padding-lg;
    padding-right: @padding-lg;
  }
}

@media screen and (min-width: @screen-xxl) {
  .fcd-layout {
    max-width: 1320px;
    padding-left: @padding-lg;
    padding-right: @padding-lg;
  }
}
@ant-prefix: fcd;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@primary-color: #6ec72d;@link-color: #6ec72d;@body-background: #f6f9fc;@component-background: #ffffff;@text-color: #172B4D;@text-color-inverse: #ffffff;@icon-color: #6ec72d;@icon-color-hover: #00d924;@layout-body-background: @body-background;@layout-header-background: @body-background;@layout-header-height: 65px;@layout-header-padding: 0 50px;@menu-bg: transparent;@form-vertical-label-padding: 0 0 0;