@import "~antd/lib/style/themes/default.less";

.booking {
  .fcd-col-price {
    text-align: center;
    margin-bottom: 30px;

    .fcd-booking-price {
      .fcd-btn {
        font-weight: bold;
        font-size: 20px;

        height: auto;
        min-width: 250px;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;

        color: #fff;
        background-color: #6ec72d;

        &:hover {
          color: #000;
        }
      }
    }

    .fcd-booking-label {
      font-size: 14px;

      &.big {
        font-size: 24px;
        color: #6ec72d;
        text-align: right;
        font-weight: bold;
      }
    }
  }
}

.fcd-booking-submit {
  width: 100%;
  max-width: 100%;
  font-weight: bold;
}

.fcd-booking-submit > .fcd-btn-loading-icon .anticon {
  color: white;
}

@media screen and (min-width: @screen-md) {
  .booking {
    .fcd-col-price {
      text-align: center;
      margin-bottom: 30px;
  
      .fcd-booking-price {
        .fcd-btn {
          font-size: 25px;
        }
      }
    }
  }  
  .fcd-booking-submit {
    width: 100%;
    max-width: 300px;
  }
}
@ant-prefix: fcd;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@primary-color: #6ec72d;@link-color: #6ec72d;@body-background: #f6f9fc;@component-background: #ffffff;@text-color: #172B4D;@text-color-inverse: #ffffff;@icon-color: #6ec72d;@icon-color-hover: #00d924;@layout-body-background: @body-background;@layout-header-background: @body-background;@layout-header-height: 65px;@layout-header-padding: 0 50px;@menu-bg: transparent;@form-vertical-label-padding: 0 0 0;